import React, { useState, useEffect } from 'react';
import { CTHomeContainer } from "./assets/CTHomeContainer.style";

const CTHome = () => {

  return (
    <>
      <CTHomeContainer>
        <div className='new-line'>          
          <iframe width="1062"
                height="600"
                src= "https://circthread.com/"
                title="Circthread" > 
          </iframe>        
         </div>
      </CTHomeContainer>

    </>
  );
};

export default CTHome;
