import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { FooterContainer } from "./assets/FooterContainer.style";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
const Footer = ({ t }) => {

  const menu = useRef(null);

  const items = [
    {
      label: "Social Media",
      items: [
        {
          label: "Instagram",
          icon: "pi pi-instagram",
          command: () => {
            window.open ( "https://instagram.com/circthread","_blank");
          },
        },
        {
          label: "Twitter",
          icon: "pi pi-twitter",
          command: () => {
            window.open = ("https://twitter.com/circthread", "_blank");
          },
        },
        {
          label: "Linkedin",
          icon: "pi pi-linkedin",
          command: () => {
            window.location.href =
              "https://www.linkedin.com/company/circthread-project/about";
          },
        },
        {
          label: "Youtube",
          icon: "pi pi-youtube",
          command: () => {
            window.location.href =
              "https://www.youtube.com/@circthreadprojecteu8834";
          },
        },
        {
          label: "Github",
          icon: "pi pi-github",
          command: () => {
            window.location.href = "#";
          },
        },
        {
          label: "Gitlab",
          icon: "pi pi-github",
          command: () => {
            window.location.href = "#";
          },
        },
      ],
    },
    {
      label: "About Us",
      items: [
        {
          label: t("termsAndConditions"),
          url: "/TermsAndConditions",
        },
        {
          label: t("privacyPolicy"),
          url: "/PrivacyPolicy",
        },
        {
          label: t("cookiesPolicy"),
          url: "/CookiesPolicy",
        },
      ],
    },
  ];
  return (
    <FooterContainer>
      <div className="up-section">
        <div className="left-section">
          <a
            href="https://instagram.com/circthread"
            target="_blank"
            rel="noreferrer"
          >
            <i className="pi pi-instagram"></i>
          </a>
          <a
            href="https://twitter.com/circthread"
            target="_blank"
            rel="noreferrer"
          >
            <i className="pi pi-twitter"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/circthread-project/about"
            target="_blank"
            rel="noreferrer"
          >
            <i className="pi pi-linkedin"></i>
          </a>
          <a
            href="https://www.youtube.com/@circthreadprojecteu8834"
            target="_blank"
            rel="noreferrer"
          >
            <i className="pi pi-youtube"></i>
          </a>
          <a href="#s" target="_blank" rel="noreferrer">
            <i className="pi pi-github"></i>
          </a>
          <a href="#a" target="_blank" rel="noreferrer">
            <i className="pi pi-github"></i>
          </a>
          <Menu model={items} popup ref={menu} id="popup_menu" />
          <Button
            icon="pi pi-bars"
            onClick={(event) => menu.current.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
            className="menu-button p-button-secondary p-button-text"
          />
        </div>
        <div className="mid-section">
          <a href="/TermsAndConditions">{t("termsAndConditions")}</a>&nbsp;|
          <a href="/PrivacyPolicy">{t("privacyPolicy")}</a>&nbsp;|
          <a href="/CookiesPolicy">{t("cookiesPolicy")}</a>
        </div>
        <div className="right-section">
          <span className="footer-text">{t("footerCopyright")} </span>

          <img
            src={"assets/img/ct_logo.png"}
            alt="ct"
            className=""
            style={{ width: "150px", height: "50px" }}
          />
        </div>
      </div>
      <div className="down-section">
        <img
          style={{ maxHeight: "35px", width: "auto" }}
          src="/assets/img/eu.jpg"
          alt="eu_flag"
        />
        <span>{t("footerEUtext")}</span>
      </div>
    </FooterContainer>
  );
};

export default withTranslation()(Footer);
