import apios from "../common/apios";
import { TOKEN_USER,isLoggedIn,isCTAdmin } from "../common/token-check";

class AuthService {

  login(loginData){
    return apios.post("auth/login",{
      username:loginData.username,
      password:loginData.password
    })
    .then((response) => {
   

      if (response.data.token) {
        localStorage.setItem(
          TOKEN_USER,
          JSON.stringify(response.data)
        );
      }
      return response.data;
    });

  }

  changePassword(username,oldPassword , newPassword) {
    return apios.post("auth/changePassword", {
      username,
      oldPassword,
      newPassword,
    });
  }

  logout() {
    localStorage.removeItem(TOKEN_USER);
  }

}

export default new AuthService();
