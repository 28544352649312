import styled from "@emotion/styled";


export const NavbarContainer = styled.div`
   flex: 1;
   width: 100%;

   #menuBar {
    height: 60px !important;
    width: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    border-radius: 0px !important;
  }


  .p-submenu-list {
    z-index: 1000;
  }

  .p-menubar {
    background-color: #fff !important;
    height: 135px !important;
    justify-content: space-between;
    color: #fff !important;
    padding-right: 25px;
    padding: 0;
  }

  .p-menubar .p-menubar-root-list {
    width: 100%;

    justify-content: flex-end;
    // padding-right: 20px;
  }

  .p-menubar .p-menuitem {
    color: #fff !important;
    // padding-left: 5px;
    // padding-right: 5px;
  }

  .p-menubar .p-menuitem-special {
    background-color: var(--platform-primary-color) !important;
    border-radius: var(--border-radius-small);
    padding-left: 5px;
    padding-right: 5px;
  }

  .p-menubar .p-menuitem-special:hover {
    background-color: var(--platform-secondary-color) !important;
    border-radius: var(--border-radius-small);
    padding-left: 5px;
    padding-right: 5px;
  }

  .p-menubar .p-menuitem-special:hover .p-menuitem-text {
    background-color: var(--platform-secondary-color) !important;
    color: var(--color-white) !important;
  }

  .p-menubar .p-menuitem-special .p-menuitem-text {
    background-color: var(--platform-primary-color) !important;
    color: var(--color-white) !important;
  }

  // .p-menu-list {
  //   align-items: flex-end;
  // }

  // .p-menuitem-text {
  //   color: var(--platform-primary-color) !important;
  //   font-size: var(--menu-font-size) !important;
  // }

 
`;