import React from "react";
import { withTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";

const WaitDialog = ({ loading, t }) => {
  return (
    <Dialog
      // header={t("loading")}
      modal={true}
      resizable={false}
      draggable={false}
      closeOnEscape={false}
      visible={loading}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "30vw" }}
      closable={false}
    >
      <center>
        {t("pleaseWait")}...
        <br />
        <img src="/assets/img/ctloading.gif" alt={t("pleaseWait")} />
      </center>
    </Dialog>
  );
};

export default withTranslation()(WaitDialog);
