import { ServicesCardsContainer } from "./assets/ServicesCardsContainer.style";
import { withTranslation } from "react-i18next";
import WaitDialog from "./WaitDialog";
import { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";


const ServicesCards = ({ t, item }) => {
  const [loading, setLoading] = useState(false);
  const [viewServiceVisible, setViewServiceVisible] = useState(false);
  const [serviceData, setServiceData] = useState([]);

  const getServiceInfo = (id) => {
    SysService.getRegisteredServiceById(id).then(
      (response) => {

        setServiceData(response.data);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
      }
    );
  };
  const actionTemplateStatus = (data) => {
    return (
      <Chip
        label={t("STATUS_" + data.status)}
        className="mr-2 mb-2"
        style={{ backgroundColor: "#382c7c" ,color:"#fff"}}
      />
    );
  };
  const typeListTemplate = (data) => {
    return (
      <>
        <div>
          {/* if type 1 or 2 change bckgrnd clr */}
          {(() => {
            switch (data?.type) {
              case "IFRAME":
                return (
                  <Chip
                    label={data?.type}
                    className="mr-2 mb-2"
                    style={{ backgroundColor: "#72bfbf",color: "#fff" }}
                  />
                );
              case "API":
                return (
                  <Chip
                    label={data?.type}
                    className="mr-2 mb-2"
                    style={{ backgroundColor: "#83bf4f", color: "#fff" }}
                  />
                );
              default:
                return "";
            }
          })()}
        </div>
      </>
    );
  };
  const handleShowCard = () => {
    return (
      <ServicesCardsContainer
        cardOpacity={0.8}
        cardBgColor={"#382c7c"}
        nameColor="#fff"
        countryColor="#21ACE3"
        iconColor="#116182"
        iconGap={1.5}
        roleColor="#21ACE3"
        borderColor={"none"}
        logoBgColor={"#fff"}
      >
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css"
        />
        <div className="logo-container">
          <div className="info">
            <div className="name">{item?.name}</div>
            {/* <div className="serviceType">{t(item?.type)}</div> */}
            {item?.type !== undefined &&
              item?.type !== "" &&
              item?.type !== null && (
                // item?.type === "IFRAME" &&
                <div className="serviceEndpoint">
                  {/* <a
                  href={item?.endpoint}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  {t("serviceEndpoint")}
                </a> */}
                </div>
              )}
          </div>
        </div>

        <div className="role">
        </div>
        <div className="action-buttons">
          <Button>
            <span>
              <i
                class="fa fa-computer-mouse action-btn-icon"
                title={t("operateService")}
              ></i>
            </span>
          </Button>
          <Button
            onClick={() => {
              setViewServiceVisible(true);
              getServiceInfo(item?.id);
            }}
          >
            <span>
              <i class="fa fa-info action-btn-icon" title={t("info")}></i>
            </span>
          </Button>
          {item?.status === 2 && (
            <>
              <Button>
                <span>
                  <i
                    className="pi pi-eye action-btn-icon"
                    title={t("preview")}
                  ></i>
                </span>
              </Button>
            </>
          )}
        </div>
        <Dialog
          visible={viewServiceVisible}
          modal
          header={t("serviceInfo")}
          className="dialog-size"
          onHide={() => {
            setViewServiceVisible(false);
            setServiceData([]);
          }}
        >
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          >
          </div>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <p>{t("serviceName")}</p>
                <InputText
                  id="serviceName"
                  type="text"
                  disabled
                  className="p-inputtext-sm w-full view-container"
                  value={serviceData?.name}
                  style={{width:"90%"}}
                />
                
              </div>
              <div className="p-float-label input-container">
                <p>{t("serviceOrganisationName")}</p>
                <InputText
                  id="serviceOrganisationName"
                  type="text"
                  disabled
                  className="p-inputtext-sm w-full view-container"
                  value={serviceData?.organisationName}
                  style={{width:"90%"}}
                />
              </div>
              <div className="p-float-label input-container">
                <p>{t("serviceEndpoint")}</p>
                <InputText
                  id="endpoint"
                  type="text"
                  disabled
                  className="p-inputtext-sm w-full view-container"
                  value={serviceData?.endpoint}
                  style={{width:"90%"}}
                />
              </div>
              <div className="p-float-label input-container">
                <p>{t("serviceregistrationTime")}</p>
                <InputText
                  id="registrationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    serviceData?.registrationTime
                  )}
                  style={{width:"90%"}}
                />
              </div>
              <div className="p-float-label input-container">
                <p>{t("servicelastModificationTime")}</p>
                <InputText
                  id="lastModificationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    serviceData?.lastModificationTime
                  )}
                  style={{width:"90%"}}
                />
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("serviceStatus")}</p>
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("serviceType")}</p>

                {typeListTemplate(serviceData)}

              </div>
            </div>
          </form>
        </Dialog>
      </ServicesCardsContainer>
    );
  };
  return (
    <>
      {handleShowCard()}
      <WaitDialog loading={loading} lang={t} />
    </>
  );
};
export default withTranslation()(ServicesCards);
