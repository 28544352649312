import React from 'react'
import { PageNotFoundContainer } from './assets/PageNotFoundContainer.style'
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';

const PageNotFound = ({t}) => {
  return (
    <PageNotFoundContainer>
        <p>{t("404_Text")}</p>
        <div className='header-container'>
        <img className='header-img' src='/assets/img/404.png' alt={t("404authMsg")}/>
        <div className='header-text'>{t("pageNotFound")}</div>
        </div>
        <div className='description'>
            {t("notFoundText")}
        </div>
        <NavLink to={"/"}>
        <Button label={t("goToHomePage")} className='backToHomeBtn'/>
        </NavLink>
    </PageNotFoundContainer>
  )
}

export default withTranslation()(PageNotFound);