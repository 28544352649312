import { PrivacyPolicyContainer } from './assets/PrivacyPolicyContainer.style'
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';

const CookiesPolicy = ({t}) => {
    return (
      <PrivacyPolicyContainer>
          <p>{t("ct_cp")}</p>

          <div className='description'>
              {t("notFoundText")}
          </div>
          {/* <NavLink to={"/"}>
          <Button label={t("goToHomePage")} className='backToHomeBtn'/>
          </NavLink> */}
      </PrivacyPolicyContainer>
    )
  }
  
  export default withTranslation()(CookiesPolicy);