import styled from "@emotion/styled";
export const PageNotFoundContainer = styled.div`
  max-width: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-img {
      object-fit: contain;
      height: 200px;
      width: 250px;
    }
    .header-text {
      font-size: 20px;
      line-height: 20px;
      font-weight: 800;
    }
  }

  .description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
  }

  .backToHomeBtn {
    background-color: #382c7c;
    border-radius: 6px;
    border-style: solid;
    border-color: #382c7c;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;