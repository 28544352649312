import styled from "@emotion/styled";
export const ServicesCardsContainer = styled.div`
  height: 230px;
  /* flex: 0 0 calc(33.3333% - 1rem); */
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 6px;
  background-color: ${(props) => props.cardBgColor || "#fff"};
  opacity: ${(props) => props.cardOpacity};
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 2px solid ${(props) => props.borderColor};
  width: 100%;

  &:hover{
    border-color: #00a3ff;
    border-radius: 10px;
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
  }

  .logo-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
    .logo-wrapper {
      background-color: ${(props) => props.logoBgColor};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      max-height: 60px;
    }
    .logo {
      height: 54px;
      width: 54px;
      object-fit: contain;
      border-radius: ${(props) => props.logoRadius};
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.1rem;
    text-align: left;

    .name {
      color: ${(props) => props.nameColor};
      font-weight: bold;
    }

    .serviceType {
      color: ${(props) => props.countryColor};
    }

    .serviceLink {
      color: ${(props) => props.nameColor};
    }
  }

  .role {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: ${(props) => props.roleColor || "#fff"};
    gap: 0.2rem;
    flex: 1;
    text-align: left;
  }

  .role * {
    font-size: 12px;
  }
  .input-container{
    .view-container{
      width: 100%;
    }
  }

  .action-buttons {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: ${(props) => props.iconGap}rem;
    max-height: 40px !important;


    .icon {
      font-size: 1.3em;
      cursor: pointer;
      color: ${(props) => props.iconColor};
    }

    action-btn-icon {
      cursor: pointer;   
    }
    action-btn-icon :hover {
      color: ${(props) => props.iconColor};
    } 

    .badge {
      background-color: red;
    }
  }

  .action-buttons button {
    background-color: transparent;
    border: none;
  }

`;