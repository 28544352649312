import styled from "@emotion/styled";
export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 120px;
  background-color: #fff;
  flex: 1;
  padding: 0.8rem 3rem 0.8rem 3rem;
  box-sizing: border-box;
  width: 100%;

  .up-section {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    margin-top: 0.8rem;

    .left-section {
      display: grid;
      grid-template-columns: repeat(3, 35px);
      grid-gap: 5px;

      a:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        background-color: #382c7c;
      
      }
    }
  }

  .up-section .left-section a {
    width: 30px;
    height: 30px;
    background-color: #33383b;
    border-radius: 6px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    transition: all 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .mid-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 100%;
  }

  .mid-section a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }
  .mid-section a:hover{color:#00a3ff}

  .right-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    gap: 0.4rem;
  }

  .down-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .menu-button {
    display: none;
  }


  @media only screen and (max-width: 961px) {
    .up-section .left-section a{
      display: none;
    }

    .mid-section{
      display: none;
    }

    .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  

}


@media only screen and (max-width: 576px) {
   .right-section {
    display: none;
    
  }
  }
`;
