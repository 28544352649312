import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Nav = styled.div`
    height: 60px;
    left:10px;
    &:hover {
        // background: #00a3ff;
        cursor: pointer;
    }
    background: #382c7c;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 60px;
    
`;
//     margin-left: 2rem;
export const NavIcon = styled(Link)`
    font-size: 1.5rem;
    height: 60px;
    margin-left: 0.5rem;
    margin-right: 1.5rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;

`;
// #15171c
export const SidebarNav = styled.nav`
    ::-webkit-scrollbar {
    width: 5px;
    }

    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
    background: #382c7c; 
    border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #00a3ff; 
    }
    overflow:auto;
    // background: #15171c;
    background: #382c7c;

    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
    transition: 350ms;
    z-index: 10;
`;
export const SidebarNavAlt= styled.div`
    ::-webkit-scrollbar {
    width: 5px;
    }

    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
    background: #382c7c; 
    border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #00a3ff; 
    }
    overflow-y:auto;
    // background: #15171c;
    background: #382c7c;

    width: 60px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? "-100%" : "0")};
    transition: 350ms;
    z-index: 10;
`;

export const SidebarWrap = styled.div`
    width: 100%;
`;