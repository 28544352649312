import axios from "axios";
import config from "../../config";
//const API_URL = process.env.REACT_APP_SERVER_ADDRESS+"api/";

const apios = axios.create({
  baseURL: config.backendApiUrl+"/api/",
  timeout: 30000,
  timeoutErrorMessage: "Connection timeout. Please try again",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

export default apios;
