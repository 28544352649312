import styled from "@emotion/styled";

export const EditProfileContainer = styled.div`
  width: 100%;
  max-width:1000px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  border-radius: 6px;
  border-color: #00a3ff;
  border-style: solid;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align: left;

`;
export const ChangePasswordContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #595959;
  width: 100%;
  align-items: flex-start;
  padding: 2rem;
  text-align: left;

  .password-button {
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;
export const ChangePasswordDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 0 1rem;

  .input-container {
    width: 100%;
    margin-bottom: 1.5rem;

    .platformColor {
      color: #00a3ff;
    }
    .view-container {
      color: #382c7c;
      width: 50%;
    }
    .platformColor-roles {
      color: #382c7c;
      margin-top: 1rem;
    }
  }
  .submit-button {
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;
