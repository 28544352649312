import jwt_decode from "jwt-decode";

export const TOKEN_USER = "circthread-user";

export const isLoggedIn = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));
    if (user) {
      const decodedJwt = jwt_decode(user.token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  return false;
};
export const giveUsername = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));
    if (user) {
      const decodedJwt = jwt_decode(user.token);
      return decodedJwt.email;
      
    } else {
      return null;
    }
  }
  else{
    return null;
  }
}


export const isCTAdmin = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
     

      if (decodedJwt.realm_access.roles.includes("CT_ADMIN")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isCTManufacturer = () => {
  if (localStorage.getItem(TOKEN_USER)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_USER));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
     

      if (decodedJwt.realm_access.roles.includes("CT_MANUFACTURER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};