import { PrivacyPolicyContainer } from "./assets/PrivacyPolicyContainer.style";
import { withTranslation } from "react-i18next";

const PrivacyPolicy = ({ t }) => {
  return (
    <PrivacyPolicyContainer>
      <div>
        <h3 style={{ fontWeight: 700, textAlign: "left" }}>{t("ct_pp")}</h3>
        <p className="p-6" style={{ fontWeight: "normal" }}>
          {" "}
          This Privacy Policy informs you as to how we look after your personal
          data when you visit our website (regardless of where you visit it
          from) or use our services and tells you about your privacy rights and
          how the law protects you. Please take a moment to read this Privacy
          Policy which explains how we collect, use, disclose, transfer, and
          protect your information when you use our software platform,
          Circthread. By using the Circthread software, you agree to the
          collection and use of personal data in accordance with this policy.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>1. SCOPE</h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          This Privacy Policy applies to all the TRACID branded products,
          services, websites and apps offered by Ekodenge Ltd.
          <br />
          In accordance with the current European Union General Data Protection
          Regulation (Regulation (EU) 2016/679) (“GDPR”), in this Privacy Policy
          we inform you about:
          <br />
          <ul>
            <li>Who is responsible for the processing of your personal data</li>
            <li>What personal data we collect</li>
            <li>How the personal; data is collected</li>
            <li>How the personal data collected is used</li>
            <li>How and with whom the personal data is shared</li>
            <li>The security of your personal data</li>
            <li>Your rights and access to your personal data</li>
          </ul>
          <br />
          We also inform you about other data we collect that is associated with
          your account but falls outside of the category of personal data.
          <br /> <br />
          From time to time, we may update this Privacy Policy. Changes will be
          published on this Platform. When amendments are made, we will update
          the “last updated date” at the top of this Policy. Whilst we may take
          steps to notify you of changes it is your responsibility to ensure
          that you are familiar with our current policies as amended.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          2. Responsible party for processing of your data
        </h4>

        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          Your data is processed by a data controller. The data controller is
          Ekodenge A.S, registered as <strong>Ekodenge</strong> Muhendislik
          Mimarlik Danismanlik Ticaret A.S., in Ankara Turkiye. The data
          controller’s address is Hacettepe Teknokent, 1.Ar-Ge Binası, No:18,
          06800, Beytepe, Ankara Any questions in relation to this privacy
          policy and exercising of your rights can be carried out by reaching
          out to the data controller via company contact Information:
          gdpr@ekodenge.com.
        </p>

        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          3. What data we collected
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          References in this Privacy Notice to Personal Data means information
          relating to a living individual (a "Data Subject") who is, or can be,
          reasonably identified from the information, either alone or in
          conjunction with other information.
          <br /> <br />
          References in this Privacy Notice to Other Data means information that
          does not relate to a living individual, in that the individual cannot
          be reasonably identified from the information, either alone or in
          conjunction with other information.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          3.1 Personal data we collect:
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          To access certain areas of our Platform, you will be asked to register
          with us. During the registration process you will be asked to submit
          personal data about yourself, including your first name, surname and
          email address. By entering your details in the selected fields you
          allow Ekodenge to provide you with the services you select.
          <br /> <br />
          We also collect web usage statistics information automatically about
          visitors to our Platform. The information can include IP address,
          browser type and version, page on our platform that you visit, time
          and date of your visit, time spent on those pages, unique device
          identifiers, and other diagnostics data. How this is done is described
          in the section headed "Cookies and tracking technologies" below.
          <br /> <br />
          We may collect information about your location with your consent to
          provide you with specific location services, which may include
          automated language selection, described in the section headed
          “Location services” below.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          3.2 Other data that we collect
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We also collect other data that relates to products for which we
          manage their product identity and information. During the usage of our
          platform, you may be asked to fill in product information surveys,
          relating to product usage, repair, maintenance and other aspects
          relating to the life cycle of products.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          3. How we collect data
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We collect data through different routes. We collect data directly
          from you, when you register on our software platform, fill in forms,
          or interact with our customer service upon your request.
          <br />
          <br />
          We collect data automatically. Through cookies and similar tracking
          technologies as you navigate through our Software Platform. The means
          by which we do this are described in the section headed "Cookies and
          tracking technologies"
          <br />
          <br />
          We may automatically collect and receive passive data from third
          parties whose services are integrated with our software platform for
          web analytics purposes. Specific information about third party
          services is described in section 3.1 ‘Third party services
          integration’ below.
        </p>

        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          3.1. Third party services{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          Our platform may integrate services from third parties, including
          third party video streaming services offered by certain partners. We
          do not control the content or privacy practices of those third-party
          services and their functionality may be subject to those parties’
          privacy statements. <br />
          <br />
          Our platform may also refer to third party websites such as client
          websites, mobile applications and other online services or platforms.
          We do not control such third parties and we are not responsible for
          the content, availability, or security of third parties.
          <br />
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          4. Use of personal data
        </h4>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>4.1 Use purposes</h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We may lawfully use your personal data as follows:
        </p>
        <ul>
          <li>
            To enhance your user experience and maintain our Software Platform
          </li>
          <li>To notify you about changes to our Software Platform</li>
          <li>
            To allow you to participate in interactive features of our Software
            Platform
          </li>
          <li>To provide customer support </li>
          <li>
            To gather analyses or valuable information to improve our Software
            Platform
          </li>
          <li>To monitor the usage of our Software Platform </li>
          <li>To detect, prevent, and address technical issues</li>
          <li>
            To enforce usage terms and conditions and protect your privacy
            rights.{" "}
          </li>
        </ul>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          Please note that we may process your personal data for the above
          purposes without your knowledge or consent, where this is required or
          permitted by law.
          <br />
          <br />
          We will not use your personal data for any other purposes, which
          includes not using it for marketing purposes.
          <br />
          <br />
          We may also ask you to opt in for receiving updates by email about
          platform services updates, insights and online events. We may, once
          you have opted in, use your personal data to contact you to provide
          information about platform services updates, insights and online
          events.{" "}
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          4.2 Customer support management
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          In cases where you reach out to us for exercising your rights through
          gdpr@ekodenge.com or for general questions or needs, a customer
          support or engineer may need to access your personal data from our
          server.
          <br /> <br />
          Any personal data that is accessed by our customer support or engineer
          teams is deleted after a specific enquiry or support ‘ticket’ has been
          closed. No personal data is allowed to be kept on an individual’s
          machine or transferred to another server. Our customer support and
          engineer teams may be outside the European Union, residing within
          Türkiye.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          5. Sharing your information
        </h4>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          5.1 Personal data sharing
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We do not share your personal data as listed under 3.1 above with
          third parties outside TRACID except as legally permitted in the
          following limited circumstances.
          <br />
          <br />
          All personal data may be shared for business transfer purposes, in
          connection with any merger, sale of company assets, financing, or
          acquisition of all or a portion of our business to another company.
          <br /> <br />
          Passively collected information collected through cookies or tracking
          technologies from your use of our platform may be shared with external
          service providers, but only for purposes of monitoring and analysing
          the use of our software platform.
          <br /> <br />
          We may share your personal data when you have given us consent, with
          clients who are the product manufacturers of products, in cases where
          you have one or more of the client’s products associated with your
          personal data in your account. For purposes of external service
          offerings of the client in relation to the product use and management
          throughout the product life cycle.
          <br /> <br />
          All personal data may be shared for law enforcement purposes. Under
          certain circumstances, we may be required to disclose your information
          by law or in response to valid requests by public authorities (e.g., a
          court or a government agency), such as a court order, subpoena or
          search warrant or other legal requirement to detect, prevent, or
          investigate potential security incidents or fraud.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          5.2 Other data sharing
        </h4>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>(9) Indemnity </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We may share other data as listed under 3.2 with third parties as
          legally permitted under the following limited circumstances:
          <br /> <br />
          We may share all other data based on consent provided by you with
          clients who are the product manufacturers of the product that is the
          subject of the related data. For purposes of providing insights into
          the use and management of the specific manufacturer’s products
          throughout the product life cycle. In all cases all other data as
          listed under 3.2 will be shared only on an anonymised basis and
          isolated from any of your personal data.
          <br /> <br />
          All other data may be shared for business transfer purposes, in
          connection with any merger, sale of company assets, financing, or
          acquisition of all or a portion of our business to another company.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          6. Data Protection
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          The data we collect is hosted and stored in servers in Germany in the
          European union. We employ appropriate security measures to protect
          your information and keep it secure and maintained within the European
          Union. These measures include data encryption, access controls, and
          regular security audits. However, no method of transmission over the
          internet or method of electronic storage is 100% secure, so we cannot
          guarantee absolute security.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          7. Data Retention
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          In general, we will retain personal data and other data only for as
          long as is necessary for the purposes set out in this privacy policy
          and to the extent necessary to comply with our legal obligations,
          resolve disputes, and enforce our policies.
          <br /> <br />
          If you have an account with us and your account is active, we
          generally do not delete the personal data as specified in 3.1 above in
          your account, and other data as specified in 3.2 above as associated
          with your account, As long as you maintain your account you are
          responsible for and control the time periods for which we retain your
          data, by exercising your possibility to inactivate your account or
          exercise your rights described in section 7 “your rights” below.
          <br /> <br />
          If you inactivate your account, your personal data as specified in
          section 3.1 above will be securely held for a period not exceeding
          three months, unless a longer retention period is required or
          permitted by law. The other data associated with your inactivated
          account, as specified in section 3.2 above, will be securely held for
          a period not exceeding three years, unless a longer retention period
          is required or permitted by law.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>8. Your rights</h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          You have the right to access, correct, update or request deletion of
          your personal data at any time. You can also object to or restrict the
          processing of your personal data and have the right to portability of
          your personal data. To exercise these rights, please contact us at
          gdpr@ekodenge.com.
          <br /> <br />
          You have the right to withdraw your consent for processing of your
          personal data at any time. This may mean your access to certain
          services is restricted or denied as a result. Withdrawal of consent
          does not affect the lawfulness of processing based on consent before
          its withdrawal. To withdraw your consent, please contact us at
          gdpr@ekodenge.com.
          <br /> <br />
          When we receive a request, we will take reasonable steps to verify
          your identity, and we will respond to your request to exercise these
          rights within a reasonable time (and in all cases within 30 days of
          receiving a request)
          <br /> <br />
          In addition, you may have the right to complain to a data protection
          authority about our collection and use of your personal data. For more
          information, please contact your local data protection authority. A
          list of data protection authorities in European Union countries can be
          found here:
          https://www.edpb.europa.eu/about-edpb/about-edpb/members_en
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          9. Cookies and Tracking Technologies{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We use cookies and similar tracking technologies, including web
          beacons and scripts, to track the activity on our Software Platform
          and store certain information.
          <br /> <br />
          A cookie is a small simple file that is sent along with pages of our
          software platform and stored by your browser on the hard drive of your
          computer or another device. The information stored therein may be
          returned to our servers or to the servers of the relevant third
          parties during a subsequent visit.
          <br /> <br />
          A script is a piece of program code that is used to make our website
          function properly and interactively. This code is executed on our
          server or on your device.
          <br /> <br />
          A web beacon (or a pixel tag) is a small, invisible piece of text or
          image on a website that is used to monitor traffic on a website. In
          order to do this, various data about you is stored using web beacons.
          <br /> <br />
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse functional cookies connected with our software platform some
          parts of the website may become inaccessible or not function properly.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          10. Location Serices{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We may provide services that are able to use information about your
          location to improve your user experience, such as localised language
          setting. For these services, which are typically available on mobile
          devices or applications, you are provided with the opportunity to
          provide your consent to the use of location services, which, for
          example, process information deriving from GPS, sensors, beacons or
          Wi-Fi access points, in order to allow you to benefit from a more
          personalised service. Your device will have settings that allow you to
          turn off these services should you no longer wish to benefit from
          them.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          11. Contacting us{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We welcome your questions, comments, and concerns about privacy. If
          you have any questions about this Privacy Policy or our data
          practices, please contact us as follows: by e-mail at
          gdpr@ekodenge.com or write to us at Ekodenge A.S. Hacettepe Teknokent,
          1.Ar-Ge Binası, No:18, 06800, Beytepe, Ankara.
        </p>
      </div>
    </PrivacyPolicyContainer>
  );
};

export default withTranslation()(PrivacyPolicy);
