import styled from "@emotion/styled";

export const LoginContainer = styled.div`

  max-width: 800px;
  align-items: center;
  justify-content:center;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;

  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #116182;

  .form-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .header-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
    .welcome-text {
      font-weight: 600;
      color: #116182;
      font-size: 20px;
    }
    .register-text {
      font-weight: 400;
      color: #21ace3;
      font-size: 15px;
    }
  }
  .container-div {
    background-color: #fff;
    height: 100%;
    border-radius : 5px;
  }
  .new-line {
    white-space: pre-line;
    padding-bottom: 0.5rem;

  }
  .new-line-header {
    padding-top: 2.5rem;
    white-space: pre-line;
    color: #116182;
    font-weight: bold;
  }
  .loginPageLabel {
    padding-bottom: 4rem;
    
  }
  .login-button {
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
  .login-text-div{
    text-align:center;
    font-weight:bold;
    color: #116182;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    align-items: center;
    justify-content: center;
  }

  .input-container div{
    width: 100%;

    .p-component{
      width: 100%;
    }
  }


  
`;
