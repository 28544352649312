import styled from "@emotion/styled";
export const ServicesCardsSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  // gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #116182;
  width: 100%;
  align-items: flex-start;
  // padding: 2rem;

  .card-container {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }

  .no-card {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 230px;
    background-color: #dcdcdc;
    width: 100%;
    border-radius: 6px;
  }
`;