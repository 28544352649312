import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AuthService from "../functionalities/services/auth.service";

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 15px;

  &:hover {
    // background: #252831;
    background : #00a3ff;
    border-left: 2px solid #f72685;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;
// background: #252831;
const DropdownLink = styled(Link)`
  background: #252831;
  height: 60px;
  padding-left: 2.7rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 14px;

  &:hover {
    background: rgba(8, 164, 252, 0.8);
    cursor: pointer;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  return (
    <>
      {item?.visible &&
        (item?.isLogout ? (
          <SidebarLink
            // to={"/"}
            onClick={() => {
              AuthService.logout();
              window.location.href = "/";
            }}
          >
            <div>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </SidebarLink>
        ) : (
          <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
            <div>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </div>
            <div>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </div>
          </SidebarLink>
        ))}

      {subnav &&
        item?.visible &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
