import { CTPostLoginLandingContainer } from "./assets/CTPostLoginLandingContainer";

const CTPostLoginLanding = () => {

  return (
  
      <CTPostLoginLandingContainer>
      
        <div className="new-line">
          <iframe
            width="100%"
            height="700"
            src="https://circthread.com/"
            title="Circthread"
          ></iframe>
        </div>
      </CTPostLoginLandingContainer>
   
  );
};

export default CTPostLoginLanding;
