import { TermsAndConditionsContainer } from "./assets/TermsAndConditionsContainer.style";
import { withTranslation } from "react-i18next";

const TermsAndConditions = ({ t }) => {
  return (
    <TermsAndConditionsContainer>
      <div className="p-6 tnc_text_container">
        <h3 style={{ fontWeight: 700, textAlign: "left" }}>{t("ct_tnc")}</h3>
        {/* <br /> */}
        <p className="p-6" style={{ fontWeight: "normal" }}>
          {" "}
          By using our website, you accept these terms and conditions in full.
          If you disagree with these terms and conditions or any part of these
          terms and conditions, you must not use our website. If you register
          with our website or buy any product or service from our website, we
          will ask you to expressly agree to these terms and conditions. Our
          website uses cookies. By using our website and agreeing to these terms
          and conditions, you consent to our use of cookies in accordance with
          the terms of our privacy policy.
        </p>
        {/* <br />
          <br /> */}
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>(A) References</h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          References in this document (“these terms”) to “We”, “Us” and “Our”
          are to Ekodenge A.S, registered as Ekodenge Muhendislik Mimarlik
          Danismanlik Ticaret A.S., registered at Hacettepe Teknokent, 1.Ar-Ge
          Binası, No:18, 06800, Beytepe, Ankara in Ankara Turkiye. We operate the
          website ???????? (the “Software Platform”), as well as any other
          related products and services that refer or link to these legal terms
          (the “Legal Terms”) (collectively, the “Services”). Our Services
          include providing you with a means of communicating information about
          products that you as a user own or handle or require information
          about, or need to communicate about from and to other product life
          cycle partners including product users and consumers. Different
          aspects of Our Services may be presented through “click-through
          tiles”, which typically are images that a user of Our Services can
          click on to select a Service.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          2. Description of activity
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          References to “You” and “Your” in these terms are to the person
          accepting these terms as users of our software platform in general,
          whether they are our client users or product life cycle parties or
          consumers that handle a product or other user types.
        </p>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          References to “Client” or “Clients in these terms are specific to
          registered organisations and their users that want to receive access
          to specific Services that require payment, and are in the processing
          of paying fees or already pay fees for using our software platform to
          gain access to enhanced Services.{" "}
        </p>

        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          You can contact us by email at , or by mail to the above listed
          address.{" "}
        </p>

        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (B) General disclaimer
        </h4>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (1) Exclusion of warranties, representation and guarantees
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          The site and all of its text, images and software (Collectively,
          “contents”) are provided on an ‘as is’ basis without any warranties of
          any kind, whether express, implied or statutory. You agree that you
          must evaluate, and that you bear all risks associated with, the use of
          the site, including without limitation any reliance on the accuracy,
          completeness or usefulness of any content available through or in
          connection with the site.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (2) Product information
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          We make every effort to display as accurately as possible information
          about the products as provided to us by our Clients, including the
          colors, features, specifications, and details of the products
          available on our software platform. However, we do not guarantee that
          the colors, features, specifications, and details of the products will
          be accurate, complete, reliable, current, or free of other errors, and
          your electronic display may not accurately reflect the actual colors
          and details of the products.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (C) Terms and conditions of use{" "}
        </h4>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (3) Licence to use website{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          Unless otherwise stated, we own the intellectual property rights of
          the website and material on the website. Subject to the licence
          conditions below, all these intellectual property rights are reserved.
          <br />
          <br /> You may view, download for caching purposes only, and print
          pages from the website for your own personal use, subject to the
          restrictions set out below and elsewhere in these terms and conditions
          of use. You must not: (a) republish material from this website
          (including republication on another website); (b) sell, rent or
          sub-license material from the website; (c) show any material from the
          website in public; (d) reproduce, duplicate, copy or otherwise exploit
          material on our website for a commercial purpose; (e) edit or
          otherwise modify any material on the website; or (f) redistribute
          material from this website, except for content specifically and
          expressly made available for redistribution.
          <br />
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (4) Acceptable use{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          You must not use our website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of the website; or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity. You must not use
          our website to copy, store, host, transmit, send, use, publish or
          distribute any material which consists of (or is linked to) any
          spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit
          or other malicious computer software.
          <br />
          <br />
          You must not conduct any systematic or automated data collection
          activities (including without limitation scraping, data mining, data
          extraction and data harvesting) on or in relation to our website
          without our express written consent. You must not use our website to
          transmit or send unsolicited commercial communication. You must not
          use our website for any purposes related to marketing without our
          express written consent.
        </p>

        <h4 style={{ fontWeight: 600, textAlign: "left" }}>(5) Payments </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          If you want to become a Client or have established yourself as a
          Client, you agree to provide current, complete, and accurate purchase
          and account information for all purchases made via the Services. You
          further agree to promptly update account and payment information,
          including email address, payment method, and payment card expiration
          date, so that we can complete your transactions and contact you as
          needed. Sales tax will be added to the price of purchases as deemed
          required by us. We may change prices at any time. All payments shall
          be in Euros. <br />
          <br />
          You agree to pay all charges at the prices then in effect for your
          purchases and any applicable shipping fees, and you authorize us to
          charge your chosen payment provider for any such amounts upon placing
          your order. We reserve the right to correct any errors or mistakes in
          pricing, even if we have already requested or received payment.
          <br />
          We reserve the right to refuse any order placed through the Services.
          We may, in our sole discretion, limit or cancel quantities purchased
          per person, per household, or per order. These restrictions may
          include orders placed by or under the same customer account, the same
          payment method, and/or orders that use the same billing or shipping
          address. We reserve the right to limit or prohibit orders that, in our
          sole judgment, appear to be placed by dealers, resellers, or
          distributors.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (6) Restricted access{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          Access to certain areas of our website is restricted. We reserve the
          right to restrict access to other areas of our website, or indeed our
          whole website, at our discretion. If we provide you with or you
          generate a password to enable you to access restricted areas of our
          website or other content or services, you must ensure that that
          password is kept confidential. You must notify us in writing
          immediately if you become aware of any unauthorised use of your
          account or password. You are responsible for any activity on our
          website arising out of any failure to keep your password confidential,
          and may be held liable for any losses arising out of such a failure.
          You must not use any other person’s user ID and password to access our
          website. We may disable your account on the website in our sole
          discretion without notice or explanation.
          <br />
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (7) User Content{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          In these terms and conditions of use, “your content” means material
          (including without limitation text, images, audio material, video
          material and audio-visual material) that you submit to our website,
          for whatever purpose.
          <br />
          Your content (and its publication on our website) must not: (a) be
          libellous or maliciously false; (b) be obscene or indecent; (c)
          infringe any copyright, moral right, database right, trade mark right,
          design right, right in passing off, or other intellectual property
          right; (d) infringe any right of confidence, right of privacy, or
          right under data protection legislation; (e) constitute negligent
          advice or contain any negligent statement; (f) constitute an
          incitement to commit a crime; (g) be in contempt of any court, or in
          breach of any court order; (h) be in breach of racial or religious
          hatred or discrimination legislation; (i) be blasphemous; (j) be in
          breach of official secrets legislation; (k) be in breach of any
          contractual obligation owed to any person; (l) depict violence in an
          explicit, graphic or gratuitous manner; (m) be pornographic or
          sexually explicit; (n) be untrue, false, inaccurate or misleading; (o)
          consist of or contain any instructions, advice or other information
          which may be acted upon and could, if acted upon, cause illness,
          injury or death, or any other loss or damage; (p) constitute spam; (q)
          be offensive, deceptive, threatening, abusive, harassing, or menacing,
          hateful, discriminatory or inflammatory; or (r) cause annoyance,
          inconvenience or needless anxiety to any person.
          <br />
          Your content must be appropriate, civil, tasteful and accord with
          generally accepted standards of etiquette and behaviour on the
          internet. You must not use our website to link to any website or web
          page consisting of or containing material that would, were it posted
          on our website, breach the provisions of these terms and conditions.
          <br />
          You must not submit any user content to the website that is or has
          ever been the subject of any threatened or actual legal proceedings or
          other similar complaint.
          <br />
          We reserve the right to edit or remove any material submitted to our
          website, or stored on our servers, or hosted or published upon our
          website.
          <br />
          Notwithstanding our rights under these terms and conditions in
          relation to user content, we do not undertake to monitor the
          submission of such content to, or the publication of such content on,
          our website.
          <br />
          If you become aware of any content on the website that breaches these
          terms and conditions, please notify us immediately by email or using
          our website contact form.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (8) Third party websites{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          Our website includes hyperlinks to other websites owned and operated
          by third parties. These links are not recommendations. We have no
          control over the contents of third party websites, and we accept no
          responsibility for them or for any loss or damage that may arise from
          your use of them.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (D) General and interpretative provisions{" "}
        </h4>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>(9) Indemnity </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          You hereby indemnify us and undertake to keep us indemnified against
          any losses, damages, costs, liabilities and expenses (including
          without limitation legal expenses and any amounts paid by us to a
          third party in settlement of a claim or dispute) incurred or suffered
          by us arising out of any breach by you of any provision of these terms
          and conditions, or arising out of any claim that you have breached any
          provision of these terms and conditions.
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (10) Breaches of these terms and conditions{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          Without prejudice to our other rights, if you breach these terms and
          conditions of use in any way, or if we reasonably suspect that you
          have breached these terms and conditions of use in any way, we may:
          (a) send you one or more formal warnings; (b) temporarily suspend your
          access to the website; (c) permanently prohibit you from accessing the
          website; (d) block computers using your IP address from accessing the
          website; (e) contact your internet services provider and request that
          they block your access to the website; (f) bring court proceedings
          against you for breach of contract or otherwise; (g) suspend and/or
          delete your account with the website; and/or (h) delete and/or edit
          any or all of your content.
          <br />
          Where we suspend or prohibit or block your access to our website or a
          part of our website, you must not take any action to circumvent such
          suspension or prohibition or blocking (including without limitation
          creating and/or using a different account).
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (11) Entire agreement{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          These terms and conditions, together with our privacy policy,
          constitute the entire agreement between you and us in relation to your
          use of our website, and supersede all previous agreements in respect
          of your use of this website.{" "}
        </p>
        <h4 style={{ fontWeight: 600, textAlign: "left" }}>
          (12) Law and jurisdiction{" "}
        </h4>
        <p className="p-6" style={{ textAlign: "left", fontWeight: "normal" }}>
          These terms and conditions will be governed by and construed in
          accordance with Belgian law, and any disputes relating to these terms
          and conditions will be subject to the exclusive jurisdiction of the
          courts of Belgium.
        </p>
      </div>
      {/* <div className='description'>
              {t("notFoundText")}
          </div> */}
      {/* <NavLink to={"/"}>
          <Button label={t("goToHomePage")} className='backToHomeBtn'/>
          </NavLink> */}
    </TermsAndConditionsContainer>
  );
};

export default withTranslation()(TermsAndConditions);
