export const serviceTypesList = [
    {"serviceTypeName": "IFRAME", "serviceTypeCode" : 1},
    {"serviceTypeName": "API", "serviceTypeCode" : 2},
];
export const providerTypesList = [
    {"providerTypeName" : "Dpo", "providerTypeCode": 1},
    {"providerTypeName" : "Dpp", "providerTypeCode": 2},
];
export const statusTypesList = [
    // {"statusTypeName": "PENDING", "statusTypeCode" : 1},
    {"statusTypeName": "ACTIVE", "statusTypeCode" : 2},
    {"statusTypeName": "DISABLED", "statusTypeCode" : 3},
];