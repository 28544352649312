import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "primeicons/primeicons.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

import "primereact/resources/primereact.min.css";
import "react-toastify/dist/ReactToastify.css";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <App />
);

