import { format } from 'date-fns';

class GeneralFunctions {
  randomColor() {
  return "#" + Math.floor(Math.random() * 0xffffff).toString(16);
}

 handleDownloadDocument(rowData) {
  var a = document.createElement("a");
  a.target = "_blank";
  a.href = rowData.documentURL;
  a.setAttribute("download", rowData.documentName);
  a.click();
}

  diffMinutes(dt1Str) {
  var dt1 = dt1Str.split(" ");

  let ymd = dt1[0].split("-");
  let hms = dt1[1].split(":");

  var dt1 = new Date(ymd[0], ymd[1] - 1, ymd[2], hms[0], hms[1], hms[2], 0);

  return parseInt((new Date(Date.now()) - dt1) / 60000);
}

 editorFormats = [
  //'background',
  "bold",
  "color",
  "font",
  //'code',
  "italic",
  "link",
  "size",
  "strike",
  //'script',
  "underline",
  //'blockquote',
  "header",
  "indent",
  "list",
  "align",
  "direction",
  //'code-block',
  //'formula'
  // 'image'
  // 'video'
];

  getDateTemplateV1 = (data) => {
  if (data !== undefined && data !== null) {
    const d = new Date(data);
    return format(d, "dd/MM/yyyy HH:mm");
  }
};

 getDateTemplateV2 = (data) => {
    if (data !== undefined && data !== null) {
      const d = new Date(data);
      return format(d, "dd/MM/yyyy HH:mm:ss");
    }
  };
  getDateTemplateV3 = (data) => {
    if (data !== undefined && data !== null) {
      const d = new Date(data);
      return format(d, "dd/MM/yyyy");
    }
  };
  
  handlePaste = (e, targetValue, setData) => {
  e.preventDefault();

  const pastedText = (e.clipboardData || window.clipboardData).getData(
    "text/plain"
  );

  setData((prevData) => ({
    ...prevData,
    [targetValue]: pastedText,
  }));
};
}

export default new GeneralFunctions();
