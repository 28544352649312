import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLoggedIn } from "./token-check";
 
export const PublicRoute = ({ component: Component, restricted }) => {
  return isLoggedIn() && restricted ? <Navigate to="/" /> : <Component />;
}; 
export const PrivateRoute = ({ component: Component }) => {
  return isLoggedIn() ? <Component /> : <Navigate to="/login" />;
};
 
